<template>
  <v-dialog
      v-model="dialog"

      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} room</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  outlined  :error="$v.room.name.$error"
                  dense
                  v-model="room.name"
              >
                <template v-slot:label>
                  Room title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.room.name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="Capacity*"
                  outlined
                  type="number"
                  dense :error="$v.room.capacity.$error"
                  v-model="room.capacity"
              >
                <template v-slot:label>
                  Capacity
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.capacity" >**{{errors.capacity[0]}}</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                  label="Room size (sqm)*"
                  outlined @keypress="isNumber"

                  dense
                  v-model="room.room_size"
              >
                <template v-slot:label>
                  Room size (sqm)
                </template>
              </v-text-field>

            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                  label="Room features"
                  item-text="name"
                  item-value="id"
                  :items="room_attributes"
                  v-model="select_attributes"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectStates"
                  >
                    <v-list-item-action>
                      <v-icon :color="select_attributes > 0 ? 'indigo darken-4' : ''">
                        {{ iconAttributes }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              Description
              <ckeditor :config="editorConfig"
                  v-model="room.description" >
              </ckeditor>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              Status
              <v-switch
                  v-model="room.is_active"
                  :label="room.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
              <span class="text-danger" v-if="errors.is_active" >**{{errors.is_active[0]}}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            class="cancel-btn"
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="btn btn-primary"
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import {required, numeric, requiredIf} from "vuelidate/lib/validators";
import RoomService from "@/services/practical-exam/venue/room/RoomService";
import ScoreService from "@/services/score/score/ScoreService";
import RoomAttributeService from "@/services/practical-exam/venue/room-attribute/RoomAttributeService";

const roomAttribute=new RoomAttributeService;
const score = new ScoreService();
const room=new RoomService();

export default {
  validations:{
    room:{
      name:{required},
      score_id:{
      },
      capacity:{},
    }
  },
  data() {
    return {
      title: '',
      errors: [],
      scores:[],
      dialog: false,
      edit: false,
      loading: false,
      room_attributes:[],
      currentUser:{},
      room:{
        name:'',
        description:'',
        capacity:'',
        score_id:'',
        is_active:true,
        attributes_available:'',
        venue_id: null,
        room_size:'',
      },
      select_attributes:[],
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$emit('refresh');
    },
    openDialog() {
      this.dialog = true;
      this.$v.$reset()
    },
    createRoom(venueId, scoreId) {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Create";
      this.select_attributes=[];
      this.room.venue_id = parseInt(venueId);
      this.room.score_id = parseInt(scoreId);
      this.getRoomAttribute();
    },
    editRoom(item) {
      this.select_attributes=[];
      this.openDialog();
      this.edit = true;
      this.room = item;
      if(item.attributes_available){
        this.select_attributes= this.room.attributes_available.split(',').map(ele =>parseInt(ele));
      }
      this.title = "Edit";
      this.getRoomAttribute();
    },
    allSelectStates () {
      this.$nextTick(() => {
        if (this.allSelectAttributes) {
          this.select_attributes = []
        } else {
          this.select_attributes = [];
          this.room_attributes.forEach(attribute => {
            this.select_attributes.push(attribute.id);
          });
        }
      })
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {

      this.room.attributes_available=this.select_attributes.toString();
      this.loading = true;
      room
          .create(this.room)
          .then(response => {
            this.$snotify.success("Room  added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;

          })
          .catch(error => {

            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      if(this.select_attributes.length >0){
        this.room.attributes_available= this.select_attributes.toString();
      }
      room
          .update(this.room.id, this.room)
          .then(response => {
            this.$snotify.success("Room updated");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          })
    },
    resetForm(){
      this.room={
        name:'',
        description:'',
        capacity:'',
        score_id:'',
        is_active:true,
        attributes_available:'',
        venue_id: null,
      };
      this.$v.$reset();

    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },



    getRoomAttribute(){
      roomAttribute
      .all({'score_id': this.room.score_id})
          .then(response => {
            this.room_attributes=response.data.data;
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();


  },
  computed: {
    allSelectAttributes () {
      return this.select_attributes.length === this.room_attributes.length
    },
    selectAttributes () {
      return this.select_attributes.length > 0 && !this.allSelectAttributes
    },
    iconAttributes () {
      if (this.allSelectAttributes) return 'mdi-close-box'
      if (this.selectAttributes) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
}
</script>
<style scoped>
.container{
  max-width: 1000px !important;

}
.v-sheet.v-card{
  border-radius: 10px !important;
}
</style>