<template>
  <v-app>
    <div class="row">
      <v-overlay :value="isDeleting">
          <v-progress-circular
          indeterminate
          color="dark"
          ></v-progress-circular>
      </v-overlay>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Rooms</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>
                  <router-link to="/venue">\  Venues </router-link>
                  \ Rooms
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="createRoom()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new room
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field 
                    label="Room name"
                    clearable  
                    v-on:keyup.enter="searchRoom" 
                    v-model="search.info"
                    @input="search.info = $event !== null ? $event : ''"
                    outlined dense></v-text-field>
                </v-col>
<!--                <v-col class="d-flex" cols="12"  md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"  v-on:keyup.enter="searchRoom"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                      @input="search.score_id = $event !== null ? $event : ''">
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>-->
                <v-col class="d-flex" cols="12"  md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchRoom"
                      @input="search.is_active = $event !== null ? $event : ''"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                      @click="searchRoom"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Room name</th>
                  <th class="px-3">Features</th>
                  <th class="px-3">Capacity</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="rooms.length > 0" v-for="item in rooms">
                    <td class="px-2">
                      <a @click="editRoom(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.name }}
                      </a>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <ul v-if="item.feature_available_list.length>0">
                        <li v-for="list in item.feature_available_list">
                          {{ list }}
                        </li>
                      </ul>
                      <ul v-else>
                        NA
                      </ul>
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.capacity}}
                        </span>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.status_text }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editRoom(item)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit room </span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteRoom(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="rooms.length == 0">
                    <td colspan="5" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="rooms.length > 0"
                  class="pull-right mt-7"
                  @input="getAllRoom"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllRoom"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import RoomService from "@/services/practical-exam/venue/room/RoomService";
import VenueService from "@/services/practical-exam/venue/VenueService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateOrUpdate from './CreateOrUpdate';
const score = new ScoreService();
const room=new RoomService();
const venue=new VenueService();

export default {
  components:{
    CreateOrUpdate,
  },
  data(){
    return{
      search:{
        info:'',
        is_active:'',
        score_id:'',
      },
      isDeleting: false,
      venue: '',
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      rooms:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      currentUser:{},
      isLoading:false,
    }
  },
  methods:{

    getAllRoom(){
      this.isLoading=true;
      room
          .getByVenue(this.search,this.page, this.venueId)
          .then(response => {
            this.rooms=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading=false;
          })
          .catch((err) => {
          });
    },
    searchRoom(){
      this.getAllRoom();
    },
    deleteRoom(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          this.isDeleting = true;
          if (confirm) {
            room
                .delete(item)
                .then((response) => {
                  this.getAllRoom();
                  this.$snotify.success("Room  deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                  this.isDeleting = false;
                })
          }
        },
      });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
          });
    },
    createRoom(){
      this.$refs['create-or-update'].createRoom(this.venueId, this.venue.score_id);
    },
    editRoom(item){
      this.$refs['create-or-update'].editRoom(item);
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    getVenueInfo(){
      venue
      .show(this.venueId)
      .then((response) => {
        this.venue = response.data.venue;
      })
      .catch(err => {

      });
    }
  },
  computed:{
    venueId(){
      return this.$route.params.venueId;
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllRoom();
    this.getAllScore();
    this.getVenueInfo();
  }
}
</script>